import {useContext, useEffect, useState} from "react";
import AbTestsContext from "../context/abTestsContext";

const useTest = (testName) => {
  const [variant, setVariant] = useState(undefined)
  const {getVariant} = useContext(AbTestsContext)

  useEffect(() => {
    setVariant(getVariant(testName))
  }, []);

  return variant
}

export default useTest;
