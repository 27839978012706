import React, {useState, useEffect} from "react"

import "./style.scss"
import { colorTags } from "../../services/data"
import ProductItem from "../ProductItem"
import AccessoryItem from "../AccessoryItem"
import useNewRxFlow from '../../utils/useNewRxFlow'

const RelatedFrames = ({ relatedFrames, linkTo, type, isKidsRelatedGlasses }) => {
  const [headerText, setHeaderText] = useState('You May Also Like')
  const isNewRx = useNewRxFlow()

  useEffect(() => {
    if (type === 'sunglasses') {
      setHeaderText('See what other customers are buying')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="related-frames" data-type={type}>
      <div className="grid-section">
        <div className="top-block">
          <h3 className={`text h3 heading ${isNewRx ? 'noMargin' : ''}`}>{headerText}</h3>
        </div>
        <div className="frames-block">
          {relatedFrames.map((item, index) => {
            if (item?.type === "accessory") {
              return (
                <AccessoryItem
                  product={item}
                  color={colorTags[type]}
                  key={index}
                  position={index+1}
                />
              )
            }

            return <ProductItem product={item} type={type} key={index} isKidsRelatedGlasses={isKidsRelatedGlasses} position={index+1} />
          })}
        </div>
      </div>
    </div>
  )
}
export default RelatedFrames
