import React from "react"
import WebpImage from "../WebpImage"

import "./style.scss"
import useNewRxFlow from '../../utils/useNewRxFlow'

const AboutProductSection = ({ title, subtitle, type, image , description, bullet_points, heading,subheader}) => {
  const isNewRx = useNewRxFlow()
  return (
    <div className={`about-product-section ${isNewRx ? 'mt14' : ''}`} data-type={type}>
      <div className="grid-section">
        <div className="about-block">
          {subtitle &&
            <h2 className="text h11 neue-bold uppercase heading">{subtitle}</h2>
          }
          {(type === 'contacts' && !subtitle) &&
            <h2 className="text h11 neue-bold uppercase heading">{heading ? heading : "Convenient & Affordable Contacts"}</h2>
          }
          {(type === 'glasses' && !subtitle) &&
            <h2 className="text h11 neue-bold uppercase heading">{`About ${title} Eyeglasses`}</h2>
          }
          {(type === 'sunglasses' && !subtitle) &&
            <h2 className="text h11 neue-bold uppercase heading">{`About ${title} Sunglasses`}</h2>
          }
          {(type === 'accessory' && !subtitle) &&
            <h2 className="text h11 neue-bold uppercase heading">{`About ${title}`}</h2>
          }


          <h3 className="text h3 title">
            {description}
          </h3>
          {subheader &&
          <div>
            <br/>
            <p className="text h5">{subheader}</p>
          </div>
          }
          <ul className="description-list">
            {bullet_points?.map((e) => {
              return <li className="text h7 neue">
                {e}
              </li>
            })}

          </ul>
        </div>
        {image && <WebpImage className="placeholder-image" fileName={image} />}
      </div>
    </div>
  )
}

export default AboutProductSection
