import React from "react"

import WebpImage from "../WebpImage"

import "./style.scss"

const ProductValueProps = ({ props, type }) => (
  <div className="product-value-props">
    <div className="grid-section" data-type={type}>
      {props.map(({ image, label }, index) => (
        <div className="value-prop" key={index}>
          {image && <WebpImage fileName={image} alt="" />}
          <span className="prop text h8 neutral">{label}</span>
        </div>
      ))}
    </div>
  </div>
)

export default ProductValueProps
